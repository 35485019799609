import React, { useContext } from 'react';
import { SwitchContainer, SwitchLabel } from './Switch.styles';
import { OrderContext } from 'src/containers/Orders/context';
import { roundnessCalculator } from '../util';

const Switch = (props) => {
  const { onChangeActiveTab, activeTab } = useContext(OrderContext);
  const { tabsData = [], roundness = 2, fluid = false, backgroundColor, width } = props;
  const tabRoundness = roundnessCalculator(roundness) || '0px';

  return (
    <SwitchContainer
      backgroundColor={backgroundColor}
      fluid={fluid}
      fontSize={14}
      className="tw-h-[48px]"
      style={{
        borderRadius: tabRoundness,
      }}
    >
      {tabsData.map(({ text, key }) => {
        const isActiveTab = activeTab === key;

        return (
          <SwitchLabel
            key={key}
            fluid={fluid}
            width={width}
            onClick={() => onChangeActiveTab(key)}
            isActiveTab={isActiveTab}
            className="tw-relative tw-h-full tw-w-full tw-bg-transparent"
            style={{ borderRadius: tabRoundness }}
          >
            <div
              className={
                isActiveTab
                  ? 'tw-absolute -tw-inset-x-[5px] -tw-inset-y-[2px] tw-z-10 tw-flex tw-items-center tw-justify-center tw-text-white'
                  : ''
              }
              style={{
                backgroundColor: isActiveTab ? backgroundColor : 'transparent',
                borderRadius: tabRoundness,
              }}
            >
              {text}
            </div>
          </SwitchLabel>
        );
      })}
    </SwitchContainer>
  );
};

export default Switch;
