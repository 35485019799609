import styled from 'styled-components';
import { roundnessCalculator } from '../util';

export const SwitchContainer = styled.div`
  display: flex;
  flex: 0;
  width: fit-content;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  width: ${(props) => (props.fluid ? `100%` : 'fit-content')};
  outline: none;
  background-color: #eeeef1;
`;

export const switches = styled.div`
  font-size: ${(props) => props.fontSize || 14}px;
`;

export const SwitchLabel = styled.div`
  box-sizing: border-box;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;

  color: #646464;

  width: ${(props) => props.width && `${props.width} !important;`};
  width: ${(props) => props.fluid && `100% !important;`};

  @media screen and (min-width: 767px) {
    width: 100px;
    font-size: 16px;
  }

  @media screen and (max-width: 766px) {
    width: 80px;
    font-size: 14px;
  }
`;

export const SwitchToggle = styled.div`
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  background: ${(props) => props.backgroundColor || '#6E25F8'};
  color: ${(props) => props.fontColor || '#ffffff'};
  transform: translateY(-50%);
  height: calc(100% + 6px);
  border-radius: ${(props) => roundnessCalculator(props.roundness)};
  width: 50%;
  ${(props) => (!!props.isToggle ? `right: -5px;` : 'left: -5px;')}

  @media screen and (min-width: 767px) {
    font-size: 16px;
  }

  @media screen and (max-width: 766px) {
    font-size: 14px;
  }
`;

export const Toggle = styled.div`
  box-sizing: border-box;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  top: 50%;
  background: ${(props) => props.backgroundColor || '#6E25F8'};
  color: ${(props) => props.fontColor || '#ffffff'};
  height: calc(100% + 6px);
  border-radius: ${(props) => roundnessCalculator(props.roundness * 2)};
  width: 100%;
  padding: 8px 12px;

  border-top-left-radius: ${(props) => roundnessCalculator(props.roundness)};
  border-bottom-left-radius: ${(props) => roundnessCalculator(props.roundness)};
  border-top-right-radius: ${(props) => roundnessCalculator(props.roundness)};
  border-bottom-right-radius: ${(props) => roundnessCalculator(props.roundness)};

  @media screen and (min-width: 767px) {
    font-size: 16px;
  }

  @media screen and (max-width: 766px) {
    font-size: 14px;
  }
`;
